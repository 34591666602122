import { Box, Button, Image, Stack, Tooltip } from '@chakra-ui/react';
import { HStack, VStack, BoxProps, Spacer, Text } from '@chakra-ui/layout';
import { useRouter } from 'next/router';
import LocationPin from '@assets/images/location-pin.png';
import { useDesktopMediaQuery } from '@hooks/responsive_hook';
import { FeaturedCL } from '../types/featuredCL';

interface IProps extends BoxProps {
  featuredCL: FeaturedCL;
}

const FeaturedCLCard: React.FC<IProps> = ({ featuredCL, ...restProps }: IProps) => {
  const isDesktop = useDesktopMediaQuery();
  const router = useRouter();
  const { user, name, city } = featuredCL!;
  const FEATURED_CL_TEXT = `${user.first_name} from ${name}`;

  const renderVisitStoreButton = () => (
    <Button
      px="16px"
      py="8px"
      fontWeight={600}
      fontSize="12px"
      lineHeight="16px"
      color="orange.500"
      onClick={() => router.push(`/${featuredCL?.url}`)}
      {...!isDesktop && { width: '50%' }}
    >
      Visit Store
    </Button>
  );

  const renderMobileView = () => (
    <VStack justify="end" height="full" spacing="12px" alignItems="start">
      <VStack spacing="1px" alignItems="start">
        <Text
          color="white"
          fontSize="10px"
          lineHeight="16px"
          fontWeight={400}
        >
          CL OF THE DAY
        </Text>
        <Tooltip hasArrow label={FEATURED_CL_TEXT} placement="top-start">
          <Text
            color="white"
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
            noOfLines={1}
          >
            {FEATURED_CL_TEXT}
          </Text>
        </Tooltip>
      </VStack>

      <HStack spacing="4px">
        <Image width="16px" src={LocationPin.src} alt="LocationPin" />
        <Text fontSize="10px" lineHeight="16px" fontWeight={500} color="white">
          {city.toUpperCase()}
        </Text>
      </HStack>
      { renderVisitStoreButton() }
    </VStack>
  );

  const renderDesktopView = () => (
    <Stack justify="end" height="full" spacing="2px">
      <Text
        color="white"
        fontSize="10px"
        lineHeight="16px"
        fontWeight={400}
      >
        CL OF THE DAY
      </Text>

      <HStack placeItems="start" textAlign="start">
        <VStack spacing="10px" alignItems="start">
          <Tooltip hasArrow label={FEATURED_CL_TEXT} placement="top-start">
            <Text
              color="white"
              fontSize="16px"
              lineHeight="24px"
              fontWeight={600}
              noOfLines={1}
            >
              {FEATURED_CL_TEXT}
            </Text>
          </Tooltip>
          <HStack spacing="4px">
            <Image width="16px" src={LocationPin.src} alt="LocationPin" />
            <Text fontSize="10px" lineHeight="16px" fontWeight={500} color="white">
              {city.toUpperCase()}
            </Text>
          </HStack>
        </VStack>
        <Spacer />
        { renderVisitStoreButton() }
      </HStack>
    </Stack>
  );

  return (
    <Box
      onClick={() => router.push(`/${featuredCL?.url}`)}
      {...restProps}
    >
      {isDesktop && renderDesktopView()}
      {!isDesktop && renderMobileView()}
    </Box>
  );
};

export default FeaturedCLCard;
