import { Image, Button, IconButton, Circle, Tooltip, Icon, Center } from '@chakra-ui/react';
import { Box, HStack, VStack, Spacer, Text, Grid, GridItem } from '@chakra-ui/layout';
import { InfoIcon } from '@chakra-ui/icons';
import { CITY_SELECTION_TUTORIAL_STEP, STORE_SELECTION_TUTORIAL_STEP } from '@const/tutorial-steps';
import LocationSelectField from '@sarisuki/home/components/LocationSelectField';
import { setIsSearchingAddress, getFeaturedCL, getFeaturedProducts, selectHome, setTutorial, setInputtedAddress, setCityProvince, setIsFromHomeLandingPage } from '@sarisuki/home/homeSlice';
import { setLocation } from '@sarisuki/location/locationSlice';
import { getBanners, getIPAddress, getLandingPageBanners, selectStore } from '@sarisuki/store/storeSlice';
import FeaturedCLCard from '@sarisuki/home/components/FeaturedCLCard';
import TestimonialCard from '@sarisuki/home/components/TestimonialCard';
import FeaturedProductCard from '@sarisuki/home/components/FeaturedProductCard';
import LandingPageFooter from '@sarisuki/home/components/LandingPageFooter';
import LandingPageNavigationBar from '@sarisuki/home/components/LandingPageNavigationBar';
import { Testimonial } from '@sarisuki/home/types/testimonial';
import { FeaturedProduct } from '@sarisuki/home/types/featuredProduct';
import SupermartketQuality from '@sarisuki/propositionalBanners/SupermarketQuality';
import PresyongAbotKaya from '@sarisuki/propositionalBanners/PresyongAbotKaya';
import LibrengDelivery from '@sarisuki/propositionalBanners/LibrengDelivery';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Slider from 'react-slick';
import Carousel from 'react-multi-carousel';
import Marquee from 'react-fast-marquee';
import 'react-multi-carousel/lib/styles.css';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useDesktopMediaQuery, useMobileMediaQuery, useTabletMediaQuery } from '@hooks/responsive_hook';
import ConversionsViewEvent from '@utils/conversionsViewEvent';

const HomePage = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const isDesktop = useDesktopMediaQuery();
  const { ipAddress, landingPageBanners, banners } = useAppSelector(selectStore);
  const { featuredCL, featuredProducts } = useAppSelector(selectHome);
  const canonicalUrl = (`https://store.sarisuki.com${router.asPath}`).split('?')[0];

  /* Component constant variables */
  const MAIN_BG_COLOR = 'gray.100';
  const CAROUSEL_ANIMATION_INTERVAL = 2000;
  const INFO_ICON_TEXT = 'A Ka-Sari is a Community Leader that manages a SariSuki store. Pick up your orders from their store to save on delivery costs.';
  const DEFAULT_BANNER_COUNT = 2;
  const FEATURED_CL_BG_IMAGES = [
    'featured-cl-bg-image-2',
    'featured-cl-bg-image-3',
    'featured-cl-bg-image-4',
    'featured-cl-bg-image-5',
    'featured-cl-bg-image-6',
  ];

  /* Component States */
  const [findNowAddressDisabled, setFindNowAddressDisabled] = useState(true);
  const [isInfoTextOpen, setIsInfoTextOpen] = useState(false);
  const [bannerCount, setBannerCount] = useState(DEFAULT_BANNER_COUNT);
  const [featuredCLBackground, setFeaturedCLBackground] = useState('');

  useEffect(() => {
    const random_bg_index = Math.floor(Math.random() * FEATURED_CL_BG_IMAGES.length);
    setFeaturedCLBackground(FEATURED_CL_BG_IMAGES[random_bg_index]);

    dispatch(getIPAddress());
    dispatch(getLandingPageBanners());
    dispatch(getBanners());
    dispatch(setIsSearchingAddress(false));
    dispatch(setCityProvince(''));
    dispatch(setInputtedAddress(''));

    if (featuredProducts.results.length === 0) {
      dispatch(getFeaturedProducts());
    }

    if (!featuredCL) {
      dispatch(getFeaturedCL());
    }
  }, []);

  useEffect(() => {
    if (landingPageBanners && banners) {
      if ((landingPageBanners.banners.length + banners.results.length) <= 1) {
        setBannerCount(1);
      }
    }
  }, [landingPageBanners, banners]);

  useEffect(() => {
    if (ipAddress) {
      ConversionsViewEvent({ ip_address: ipAddress });
    }
  }, [ipAddress]);

  const renderFeaturedProducts = ({ paddingTop }: any) => {
    const { results } = featuredProducts;

    return (
      <>
        <div
          style={{
            paddingTop,
          }}
        >
          <Marquee
            gradient={false}
            speed={(isMobile || isTablet) ? 50 : 40}
          >
            {
              results.map((product: FeaturedProduct) => (
                <FeaturedProductCard
                  key={product.id}
                  featuredProduct={product}
                />
              ))
            }
          </Marquee>
        </div>
        <Text
          color="gray.400"
          fontSize="10px"
          lineHeight="12px"
          fontWeight={400}
          noOfLines={1}
          pt="8px"
          pb="12px"
        >
          *Prices may vary
        </Text>
      </>
    );
  };

  const testimonialsResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1.5,
      slidesToSlide: 1,
    },
  };

  const TestimonialsArrow = ({ next, previous }: any) => (
    <HStack spacing="16px" mt="12px" mx="10px">
      <IconButton
        aria-label="previous-testimonial"
        rounded="full"
        size="md"
        bg="white"
        color="sarisuki-green.500"
        onClick={() => previous()}
        icon={<Icon as={BsChevronLeft} boxSize={5} />}
      />
      <IconButton
        aria-label="next-testimonial"
        rounded="full"
        size="md"
        bg="white"
        color="sarisuki-green.500"
        onClick={() => next()}
        icon={<Icon as={BsChevronRight} boxSize={5} />}
      />
    </HStack>
  );

  const renderTestimonials = () => {
    if (!landingPageBanners?.testimonials) {
      return null;
    }

    return (
      <div
        style={{
          paddingBottom: '25px',
        }}
      >
        <Carousel
          arrows={false}
          draggable={false}
          autoPlay={false}
          shouldResetAutoplay={false}
          swipeable={isMobile}
          infinite
          showDots={false}
          responsive={testimonialsResponsive}
          renderButtonGroupOutside
          customButtonGroup={<TestimonialsArrow />}
          partialVisible
        >
          {
            featuredCL &&
            <FeaturedCLCard
              className={featuredCLBackground}
              featuredCL={featuredCL}
              borderRadius="8px"
              height="full"
              px="16px"
              py="16px"
              mx="5px"
            />
          }

          {
            landingPageBanners?.testimonials.map((userTestimonial: Testimonial) => (
              <TestimonialCard
                key={userTestimonial.id}
                userTestimonial={userTestimonial}
                bg="white"
                borderRadius="8px"
                height="full"
                p="24px"
                mx="5px"
              />),
            )
          }
        </Carousel>
      </div>
    );
  };

  const CustomDot = ({ ...props }) => {
    // eslint-disable-next-line react/prop-types
    const { onClick, active } = props;
    return (
      <Box px="4px">
        <Circle
          size="10px"
          bg={active ? 'gray.400' : 'gray.200'}
          onClick={() => onClick()}
        />
      </Box>
    );
  };

  const bannersResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: bannerCount,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const renderBannerCarousel = ({
    marginTop, paddingBottom, displayLandingpageBanners, displayStoreBanners }: any) => {
    if (!displayLandingpageBanners && !displayStoreBanners) {
      return null;
    }

    const combinedbanners = displayLandingpageBanners.concat(displayStoreBanners);
    const sorted_banners = combinedbanners.sort((a: any, b: any) => a.order - b.order);

    if (!sorted_banners) {
      return null;
    }

    return (
      <div
        style={{
          marginTop,
          paddingBottom,
          position: 'relative',
        }}
      >
        <Carousel
          customDot={<CustomDot />}
          autoPlay
          autoPlaySpeed={CAROUSEL_ANIMATION_INTERVAL}
          infinite
          arrows={false}
          draggable={false}
          swipeable={false}
          responsive={bannersResponsive}
          showDots={!isMobile}
          renderButtonGroupOutside
          renderDotsOutside
        >
          {
            sorted_banners.map((banner: any) =>
              <Image
                key={banner.id}
                src={(!isMobile && !isTablet) ?
                  (banner.image.url ?? banner.image) :
                  (banner.mobile_image.url ?? banner.mobile_image)}
                px="5px"
                borderRadius="8px"
                maxH="full"
                alt={banner.image.alt}
              />,
            )
          }
        </Carousel>
      </div>
    );
  };

  const configureTutorialStep = (step: number) => {
    dispatch(setTutorial(step));
    dispatch(setIsFromHomeLandingPage(true));
  };

  const onManualBrowseMapClick = async () => {
    await configureTutorialStep(CITY_SELECTION_TUTORIAL_STEP);
    router.push('/map');
  };

  const onFindNowAddress = async () => {
    await dispatch(setIsSearchingAddress(true));
    await configureTutorialStep(STORE_SELECTION_TUTORIAL_STEP);
    router.push('/map');
  };

  const onClickInfoIcon = () => {
    if (isInfoTextOpen) {
      setIsInfoTextOpen(false);
    } else {
      setIsInfoTextOpen(true);
    }
  };

  const handleInputChange = async (selected: any) => {
    if (selected !== null) {
      const valueTermsLength = selected.value.terms.length;
      const address = selected.value.structured_formatting.main_text;
      const city = selected.value.terms[valueTermsLength - 3].value;
      const province = selected.value.terms[valueTermsLength - 2].value;

      const coord = await geocodeByAddress(selected.label)
      .then((results) => getLatLng(results[0]));

      dispatch(
        setLocation(
          {
            accuracy: null,
            altitude: null,
            altitudeAccuracy: null,
            latitude: coord.lat,
            longitude: coord.lng,
            heading: null,
            speed: null,
          },
        ),
      );

      setFindNowAddressDisabled(false);
      dispatch(setCityProvince(`${city}, ${province}`));
      dispatch(setInputtedAddress(`${address}, ${city}, ${province}`));
      return;
    }
    setFindNowAddressDisabled(true);
  };

  const renderMobileBanners = ({ displayLandingpageBanners, displayStoreBanners }: any) => {
    if (!displayLandingpageBanners && displayStoreBanners) {
      return null;
    }

    const combinedbanners = displayLandingpageBanners.concat(displayStoreBanners);
    const sorted_banners = combinedbanners.sort((a: any, b: any) => a.order - b.order);

    if (!sorted_banners) {
      return null;
    }
    const settings = {
      dots: true,
      rows: 2,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      autoplaySpeed: 10000,
      slidesToScroll: 1,
      accessibility: true,
      autoplay: true,
    };

    return (
      <Box w="100%" alignItems="center" pt={3} mb={7}>
        <Slider {...settings}>
          {sorted_banners.map((banner: any, index: number, row: any) =>
            <Box px={1} key={banner.id}>
              <Image
                src={banner.mobile_image.url ?? banner.mobile_image}
                borderRadius="8px"
                maxH="full"
                alt={banner.mobile_image.alt ?? 'store-banner'}
              />
              {index + 1 === row.length && sorted_banners.length % 2 !== 0 &&
                <Image
                  pt="6px"
                  src={sorted_banners[0].mobile_image.url}
                  borderRadius="8px"
                  maxH="full"
                  alt={banner.mobile_image.alt}
                />
              }
            </Box>,
          )}
        </Slider>
      </Box>
    );
  };

  const renderMobileLandingPage = () => (
    <Box mx="16px" pt="24px" pb="26px" bg={MAIN_BG_COLOR}>
      { /* Navigation bar */}
      <LandingPageNavigationBar />

      {/* First Row: Propositional Banners */}
      <VStack mt="22px" spacing="8px">
        <SupermartketQuality width="full" />
        <PresyongAbotKaya width="full" />
        <LibrengDelivery width="full" />
      </VStack>

      {/* Second Row: Find Now */}
      <VStack
        width="full"
        borderRadius="8px"
        boxShadow="sm"
        border="1px"
        borderColor="gray.100"
        p="16px"
        mt="8px"
        bg="white"
      >
        <Text
          fontWeight={600}
          fontSize="16px"
          lineHeight="24px"
          color="black"
          placeSelf="start"
        >
          Mag-grocery na sa <br />
          pinakamalapit na Ka-Sari{' '}
          <Tooltip
            hasArrow
            label={INFO_ICON_TEXT}
            isOpen={isInfoTextOpen}
            placement="bottom-start"
          >
            <InfoIcon
              h="16px"
              color="orange.500"
              onMouseEnter={() => setIsInfoTextOpen(true)}
              onMouseLeave={() => setIsInfoTextOpen(false)}
              onClick={onClickInfoIcon}
            />
          </Tooltip>
        </Text>

        <Grid templateColumns="repeat(1, 1fr)" width="full">
          <LocationSelectField
            handleInputChange={handleInputChange}
          />
        </Grid>

        <Button
          bg="sarisuki-green.500"
          width="full"
          color="white"
          fontSize="16px"
          py="9px"
          px="48px"
          _hover={{ border: 'none' }}
          _active={{ border: 'none' }}
          _focus={{ border: 'none' }}
          disabled={findNowAddressDisabled}
          onClick={onFindNowAddress}
        >
          Find now
        </Button>

        <Text
          as="button"
          fontWeight="600"
          fontSize="12px"
          lineHeight="16px"
          color="orange.500"
          placeSelf="start"
          onClick={onManualBrowseMapClick}
        >
          I want to manually browse the map
        </Text>
      </VStack>

      {/* Third Row: Promo and Product Banners */}
      { landingPageBanners?.banners && banners && renderMobileBanners({
        displayLandingpageBanners: landingPageBanners?.banners.slice(),
        displayStoreBanners: banners?.results.slice() })}

      {/* Fourth Row: Featured Products */}
      { renderFeaturedProducts({ paddingTop: '12px' }) }

      {/* Fifth Row: Testimonials */}
      { renderTestimonials() }

      {/* Footer */}
      <LandingPageFooter />
    </Box>
  );

  const renderTabletLandingPage = () => (
    <Box mx="16px" py="24px" minH="100vh">
      { /* Navigation bar */}
      <LandingPageNavigationBar />

      {/* First Row: Propositional Banners */}
      <Grid mt="32px" templateColumns="repeat(2, 1fr)" gap="16px">
        <GridItem><SupermartketQuality /></GridItem>
        <GridItem><PresyongAbotKaya /></GridItem>
      </Grid>

      <Grid templateColumns="repeat(4, 1fr)" mt="16px">
        <GridItem colStart={2} colEnd={4}>
          <Box
            className="no-delivery-fees"
            borderRadius="8px"
            boxShadow="lg"
            h="96px"
          >
            <Text
              fontWeight={700}
              fontSize="24px"
              lineHeight="32px"
              color="white"
              pt="16px"
              pb="8px"
              px="20px"
            >
              LIBRENG DELIVERY*
            </Text>
            <Text
              fontWeight={400}
              fontSize="12px"
              lineHeight="16px"
              color="white"
              px="20px"
            >
              *via your nearest Ka-Sari
            </Text>
          </Box>
        </GridItem>
      </Grid>

      {/* Second Row: Find Now */}
      <HStack
        width="full"
        borderRadius="8px"
        boxShadow="sm"
        border="1px"
        borderColor="gray.100"
        bg="white"
        mt="20px"
        px="16px"
        py="17px"
      >
        <Box w="50%">
          <Text
            fontWeight={600}
            fontSize="16px"
            lineHeight="24px"
            color="black"
          >
            Mag-grocery na sa <br />
            pinakamalapit na Ka-Sari{' '}
            <Tooltip
              hasArrow
              label={INFO_ICON_TEXT}
              isOpen={isInfoTextOpen}
              placement="bottom"
            >
              <InfoIcon
                h="16px"
                color="orange.500"
                onMouseEnter={() => setIsInfoTextOpen(true)}
                onMouseLeave={() => setIsInfoTextOpen(false)}
                onClick={onClickInfoIcon}
              />
            </Tooltip>
          </Text>
        </Box>

        <Grid gap="4" templateColumns="repeat(3, 1fr)" width="full">
          <GridItem colSpan={2}>
            <LocationSelectField
              handleInputChange={handleInputChange}
            />
            <Text
              as="button"
              fontWeight="600"
              fontSize="12px"
              lineHeight="16px"
              color="orange.500"
              placeSelf="start"
              onClick={onManualBrowseMapClick}
            >
              I want to manually browse the map
            </Text>
          </GridItem>
          <GridItem>
            <Button
              backgroundColor="sarisuki-green.500"
              color="white"
              fontSize="16px"
              py="9px"
              px="48px"
              _hover={{ border: 'none' }}
              _active={{ border: 'none' }}
              _focus={{ border: 'none' }}
              disabled={findNowAddressDisabled}
              onClick={onFindNowAddress}
            >
              Find now
            </Button>
          </GridItem>
        </Grid>
      </HStack>

      {/* Third Row: Promo and Product Banners */}
      { landingPageBanners?.banners && banners && renderBannerCarousel({ marginTop: '20px', paddingBottom: '32px', displayLandingpageBanners: landingPageBanners?.banners.slice(), displayStoreBanners: banners?.results.slice() })}

      {/* Fourth Row: Featured Products */}
      { renderFeaturedProducts({ paddingTop: '24px' }) }

      {/* Fifth Row: Testimonials */}
      {renderTestimonials()}

      <Spacer />
      {/* Footer */}
      <LandingPageFooter />
    </Box>
  );

  const renderDesktopLandingPage = () => (
    <Box
      px={{ md: '16px', lg: '16px', xl: '118px' }}
      py={{ md: '24px', lg: '24px', xl: '40px' }}
      w="full"
      maxW="1440px"
    >
      { /* Navigation bar */}
      <LandingPageNavigationBar />

      {/* First Row: Propositional Banners */}
      <Grid mt="44px" templateColumns="repeat(3, 1fr)" gap="32px">
        <GridItem><SupermartketQuality /></GridItem>
        <GridItem><PresyongAbotKaya /></GridItem>
        <GridItem><LibrengDelivery /></GridItem>
      </Grid>

      {/* Second Row: Find Now */}
      <HStack
        width="full"
        borderRadius="8px"
        boxShadow="sm"
        border="1px"
        borderColor="gray.100"
        bg="white"
        mt="20px"
        px="106px"
        py="33px"
      >
        <Box width="full">
          <Text
            fontWeight={700}
            fontSize="24px"
            lineHeight="32px"
            color="black"
          >
            Mag-grocery na sa <br />
            pinakamalapit na Ka-Sari{' '}
            <Tooltip
              hasArrow
              label={INFO_ICON_TEXT}
              placement="bottom"
            >
              <InfoIcon
                h="16px"
                color="orange.500"
              />
            </Tooltip>
          </Text>
        </Box>

        <Grid gap="4" templateColumns="repeat(3, 1fr)" width="full">
          <GridItem colSpan={2}>
            <LocationSelectField
              handleInputChange={handleInputChange}
            />

            <Text
              as="button"
              fontWeight="600"
              fontSize="12px"
              lineHeight="16px"
              color="orange.500"
              placeSelf="start"
              onClick={onManualBrowseMapClick}
            >
              I want to manually browse the map
            </Text>
          </GridItem>
          <GridItem>
            <Button
              backgroundColor="sarisuki-green.500"
              color="white"
              fontSize="16px"
              py="9px"
              px="48px"
              _hover={{ border: 'none' }}
              _active={{ border: 'none' }}
              _focus={{ border: 'none' }}
              onClick={onFindNowAddress}
              disabled={findNowAddressDisabled}
            >
              Find now
            </Button>
          </GridItem>
        </Grid>
      </HStack>

      {/* Third Row: Promo and Product Banners */}
      { landingPageBanners?.banners && banners && renderBannerCarousel({ marginTop: '20px', paddingBottom: '32px', displayLandingpageBanners: landingPageBanners?.banners.slice(), displayStoreBanners: banners?.results.slice() })}

      {/* Fourth Row: Featured Products */}
      { renderFeaturedProducts({ paddingTop: '32px' }) }

      {/* Fifth Row: Testimonials */}
      { renderTestimonials() }

      {/* Footer */}
      <LandingPageFooter />
    </Box>
  );

  return (
    <>
      <Head>
        <title>SariSuki | Order fresh and affordable groceries</title>
        <meta name="description" content="Presyong Abot-Kaya. Supermarket Quality. Libreng Delivery. Order groceries from SariSuki!" />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <Box bg={MAIN_BG_COLOR} h="100%" w="100%">
        { isMobile && renderMobileLandingPage() }
        { isTablet && renderTabletLandingPage() }
        <Center>{ isDesktop && renderDesktopLandingPage() }</Center>
      </Box>
    </>
  );
};

export default HomePage;
