import { Avatar } from '@chakra-ui/react';
import { HStack, VStack, StackProps, Spacer, Text } from '@chakra-ui/layout';

import emojis from 'src/theme/emojis';
import { Markup } from 'interweave';
import { Testimonial } from '../types/testimonial';

interface IProps extends StackProps {
  userTestimonial: Testimonial;
}

const TestimonialCard: React.FC<IProps> = ({ userTestimonial, ...restProps }: IProps) => {
  const { body, author, author_title } = userTestimonial;
  const random_index = Math.floor(Math.random() * emojis.length);
  const emoji = emojis[random_index];

  const generalTextProps = {
    fontSize: '14px',
    lineHeight: '20px',
  };

  return (
    <VStack
      {...restProps}
    >
      <Text fontWeight="400" {...generalTextProps}>
        &ldquo;
        <Markup
          content={body}
          disableLineBreaks
          blockList={['p']}
        />
        &rdquo;
      </Text>
      <Spacer />
      <HStack mt="19px" placeSelf="start">
        <Avatar
          bg="gray.100"
          icon={<span role="img">{emoji}</span>}
          height="33px"
          width="33px"
        />
        <VStack spacing="0" alignItems="start">
          <Text color="gray.900" p="0" fontWeight="600" {...generalTextProps}>
            {author}
          </Text>
          <Text color="gray.500" p="0" fontWeight="400" {...generalTextProps}>
            {author_title}
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default TestimonialCard;
