import { Box } from '@chakra-ui/react';
import { BoxProps, Text } from '@chakra-ui/layout';

interface IProps extends BoxProps {}

const LibrengDelivery: React.FC<IProps> = ({ ...restProps }: IProps) => (
  <Box
    className="no-delivery-fees"
    borderRadius="8px"
    boxShadow="lg"
    maxH="105px"
    minH="96px"
    {...restProps}
  >
    <Text
      fontWeight={700}
      fontSize="24px"
      lineHeight="32px"
      color="white"
      pt="16px"
      pb="8px"
      px="20px"
    >
      LIBRENG DELIVERY*
    </Text>
    <Text
      fontWeight={400}
      fontSize="12px"
      lineHeight="16px"
      color="white"
      px="20px"
    >
      *via your nearest Ka-Sari
    </Text>
  </Box>
);

export default LibrengDelivery;
