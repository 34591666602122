import { Box, Image, Tooltip } from '@chakra-ui/react';
import { HStack, VStack, BoxProps, Text } from '@chakra-ui/layout';
import { FeaturedProduct } from '../types/featuredProduct';

interface IProps extends BoxProps {
  featuredProduct: FeaturedProduct;
}

const FeaturedProductCard: React.FC<IProps> = ({ featuredProduct, ...restProps }: IProps) => {
  const {
    name,
    images,
    on_sale,
    super_value,
    unit_of_measure,
    sale_srp_price,
    default_srp_price,
  } = featuredProduct;

  const image = images && images.length > 0 ? images[0].url : '';

  const formatPrice = (price: number) => {
    const displayNumber = parseFloat(price.toString()).toFixed(2);
    return (displayNumber === 'NaN' ? 0 : displayNumber);
  };

  const slashed_price = formatPrice(default_srp_price);
  const selling_price = on_sale ? formatPrice(sale_srp_price) : slashed_price;

  return (
    <Box
      bg="white"
      p="15px"
      mr="10px"
      borderRadius="8px"
      height="145px"
      {...restProps}
    >
      <HStack spacing="8px">
        {/* Image */}
        <Box placeSelf="start" w="71px" h="full" bg="gray.300" borderRadius="8px">
          <Image
            src={image}
            alt="product-thumbnail"
            objectFit="cover"
            fallbackSrc="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/e596a334-ec86-4a84-96df-17900077efc2/d7gwtxy-a0648d53-d900-425d-85e4-96fdeb5e7968.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwic3ViIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsImF1ZCI6WyJ1cm46c2VydmljZTpmaWxlLmRvd25sb2FkIl0sIm9iaiI6W1t7InBhdGgiOiIvZi9lNTk2YTMzNC1lYzg2LTRhODQtOTZkZi0xNzkwMDA3N2VmYzIvZDdnd3R4eS1hMDY0OGQ1My1kOTAwLTQyNWQtODVlNC05NmZkZWI1ZTc5NjguZ2lmIn1dXX0.EUXeqrmX0WznMmIeDsU2e2oViUjumxXkYxFrK3A1OOY"
          />
        </Box>

        <VStack placeSelf="start" align="start" spacing="4px" width="166px">
          {/* Super Value tag */
            super_value &&
            <Box
              bg="red.600"
              color="white"
              fontWeight={700}
              fontSize="10px"
              lineHeight="12px"
              p="4px 8px"
              borderRadius="4px"
            >
              Super Value
            </Box>
          }

          {/* Product Name */}
          <Text
            width="full"
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
            noOfLines={super_value ? 1 : 2}
          >
            <Tooltip
              hasArrow
              placement="top-start"
              label={name}
            >
              {name}
            </Tooltip>
          </Text>

          {/* UOM */}
          <Text
            width="full"
            color="gray.500"
            fontSize="12px"
            lineHeight="16px"
            fontWeight={400}
            noOfLines={1}
          >
            {unit_of_measure}
          </Text>

          {/* Default and Sale Price */}
          <HStack width="full" spacing="8px">
            <Text
              color="red.500"
              fontSize="24px"
              lineHeight="36px"
              fontWeight={700}
              noOfLines={1}
              pt="4px"
            >
              ₱{selling_price}
            </Text>

            {
              on_sale &&
              <Text
                as="s"
                color="gray.400"
                fontSize="12px"
                lineHeight="16px"
                fontWeight={400}
                noOfLines={1}
                pt="4px"
              >
                ₱{slashed_price}
              </Text>
            }
          </HStack>
        </VStack>

      </HStack>
    </Box>
  );
};

export default FeaturedProductCard;
