import { Icon } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout';
import { HiOutlineLocationMarker } from 'react-icons/hi';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { components } from 'react-select';
import { ErrorBoundary } from 'react-error-boundary';
import log from '@utils/log';
import * as Sentry from '@sentry/react';
import { useMobileMediaQuery } from '@hooks/responsive_hook';
import { GOOGLE_API_KEY } from '@const/api';
import { useState } from 'react';

const customStyles = {
  input: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    width: '100%',
  }),
};

const SelectControl = ({ children, ...props }: any) => (
  <components.Control {...props}>
    <Icon as={HiOutlineLocationMarker} color="orange.500" boxSize={7} pl={1} /> {children}
  </components.Control>
);

const SelectInput = (props: any) => (
  <components.Input
    inputClassName="border-0 focus:border-0 ring-0"
    className="border-0"
    {...props}
    isHidden={false}
  />
);

const AddressOption = (props: any) => {
  const { data } = props;
  const { value } = data;
  const { structured_formatting } = value;
  const { main_text, secondary_text } = structured_formatting;

  return (
    <components.Option {...props}>
      <Text as="span" fontSize="14px" lineHeight="20px" color="black" fontWeight={400}>
        { main_text }
      </Text>{' '}
      <Text as="span" fontSize="14px" lineHeight="20px" color="gray.600" fontWeight={400}>
        { secondary_text }
      </Text>
    </components.Option>
  );
};

const LocationSelectField = ({ handleInputChange, ...restProps }: any) => {
  const isMobile = useMobileMediaQuery();
  const [inputValue, setInputValue] = useState('');

  const renderErrorFallback = () => null;

  const myErrorHandler = async (error: Error, info: { componentStack: string }) => {
    log({
      error,
      info,
      errorId: '',
      isMobile,
    });
    Sentry.captureException(error);
  };

  const onInputChange = (inputAddress: any, { action }: any) => {
    if (action === 'input-change') {
      setInputValue(inputAddress);
    }
  };

  const onChange = (option: any) => {
    setInputValue(option ? option.label : '');
    handleInputChange(option);
  };

  return (
    <ErrorBoundary FallbackComponent={renderErrorFallback} onError={myErrorHandler}>
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_API_KEY}
        selectProps={{
          placeholder: 'Enter Address',
          isClearable: true,
          backspaceRemovesValue: true,
          inputValue,
          onChange,
          onInputChange,
          noOptionsMessage: () => null,
          components: {
              Input: SelectInput,
              Control: SelectControl,
              Option: AddressOption,
              DropdownIndicator: () => null,
              IndicatorSeparator: null,
          },
          styles: customStyles,
        }}
        autocompletionRequest={{
            types: ['geocode', 'establishment'],
            componentRestrictions: {
                country: ['ph'],
            },
        }}
        {...restProps}
      />
    </ErrorBoundary>
  );
};

export default LocationSelectField;
