import { Image, Link } from '@chakra-ui/react';
import { HStack, VStack, Text, Center } from '@chakra-ui/layout';

import FacebookIcon from '@assets/images/facebook.png';
import GmailIcon from '@assets/images/gmail.png';
import ViberIcon from '@assets/images/viber.png';

const LandingPageFooter = () => (
  <Center>
    <VStack>
      {/* Social Media Icons */}
      <HStack>
        {/* Facebook */}
        <Link
          href="https://www.facebook.com/SARISUKI.PH"
          _focus={{ border: 'none' }}
        >
          <Image height="24px" width="full" src={FacebookIcon.src} alt="facebook" />
        </Link>
        {/* Gmail */}
        <Link
          href="mailto: support@sarisuki.com"
          _focus={{ border: 'none' }}
        >
          <Image height="24px" width="full" src={GmailIcon.src} alt="gmail" />
        </Link>
        {/* Viber */}
        <Link
          href="https://invite.viber.com/?g2=AQA6J97lSQav8E1wDMJU2go0iH1TrQVDaMG%2F2RivlS08GxwFK1bbxFj6Qg8%2BIg1c"
          _focus={{ border: 'none' }}
        >
          <Image height="24px" width="full" src={ViberIcon.src} alt="viber" />
        </Link>
      </HStack>
      {/* Footer Text */}
      <Text
        color="gray.600"
        fontSize="12px"
        fontWeight="600"
        lineHeight="16px"
      >
        SariSuki Pte Ltd. © 2022. All Rights Reserved.
      </Text>
    </VStack>
  </Center>
);

export default LandingPageFooter;
