import {
  Link,
  IconButton,
  Drawer,
  DrawerBody,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { HStack, VStack, Spacer, Text } from '@chakra-ui/layout';
import { HamburgerIcon } from '@chakra-ui/icons';

import Logo from '@components/Logo';
import HomeLoginSignupButton from '@sarisuki/store/components/HomeLoginSignupButton';

import { useState } from 'react';
import { useRouter } from 'next/router';
import { useMobileMediaQuery, useTabletMediaQuery } from '@hooks/responsive_hook';

const LandingPageNavigationBar = () => {
  const router = useRouter();
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();

  /* Drawer */
  const { isOpen, onOpen, onClose } = useDisclosure();

  /* Component constants */
  const MAIN_BG_COLOR = 'gray.100';
  const ACTIVE_HOVER_STYLE = { fontWeight: 600, color: 'orange.500' };
  const INACTIVE_HOVER_STYLE = { fontWeight: 400, color: 'gray.700' };

  /* Component states */
  const [isOthersHovered, setIsOthersHovered] = useState(false);

  /* style props */
  const orderHoverStyleProp = {
    ...isOthersHovered && INACTIVE_HOVER_STYLE,
    ...!isOthersHovered && ACTIVE_HOVER_STYLE,
  };

  const onLogoClick = () => {
    router.replace('https://sarisuki.com/');
  };

  if (isMobile || isTablet) {
    return (
      <HStack>
        <HStack
          as="button"
          onClick={onLogoClick}
        >
          <Logo width="100px" height="24px" />
        </HStack>
        <Spacer />

        <IconButton
          aria-label="Mobile Home Menu"
          _active={{ border: 'none' }}
          _hover={{ border: 'none' }}
          _focus={{ border: 'none' }}
          icon={<HamburgerIcon color="gray.500" boxSize={7} />}
          onClick={onOpen}
        />

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerBody>
              <VStack alignItems="start" mt="84px" spacing="36px">
                <HomeLoginSignupButton color={MAIN_BG_COLOR} />
                <Text
                  fontSize="20px"
                  lineHeight="24px"
                  fontWeight={700}
                  color="gray.800"
                  as={Link}
                  href="https://sarisuki.com/our-story"
                >
                  About Us
                </Text>
                <Text
                  fontSize="20px"
                  lineHeight="24px"
                  fontWeight={700}
                  color="gray.800"
                >
                  Order Groceries
                </Text>
                <Text
                  fontSize="20px"
                  lineHeight="24px"
                  fontWeight={700}
                  color="gray.800"
                  as={Link}
                  href="https://sarisuki.com/ka-saris"
                >
                  Sign up as a Ka-Sari
                </Text>
                <Text
                  fontSize="20px"
                  lineHeight="24px"
                  fontWeight={700}
                  color="gray.800"
                  as={Link}
                  href="https://sarisuki.freshteam.com/jobs"
                >
                  Work at SariSuki
                </Text>
                <Text
                  fontSize="20px"
                  lineHeight="24px"
                  fontWeight={700}
                  color="gray.800"
                  as={Link}
                  href="mailto: support@sarisuki.com"
                >
                  Contact Us
                </Text>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </HStack>
    );
  }

  /* Navigation bar for Desktop devices */
  return (
    <HStack>
      <HStack
        as="button"
        onClick={onLogoClick}
      >
        <Logo width="156px" height="19px" />
      </HStack>

      <Spacer />

      <HStack spacing={isTablet ? '16px' : '36px'}>
        <Link
          href="https://sarisuki.com/our-story"
          _focus={{ border: 'none' }}
          style={{ textDecoration: 'none' }}
        >
          <Text
            fontWeight={400}
            fontSize={isTablet ? '14px' : '16px'}
            lineHeight="24px"
            color="gray.700"
            _hover={ACTIVE_HOVER_STYLE}
            onMouseOver={() => setIsOthersHovered(true)}
            onMouseOut={() => setIsOthersHovered(false)}
          >
            About Us
          </Text>
        </Link>

        <Text
          as="button"
          fontSize={isTablet ? '14px' : '16px'}
          lineHeight="24px"
          {...orderHoverStyleProp}
        >
          Order Groceries
        </Text>

        <Link
          href="https://sarisuki.com/ka-saris"
          _focus={{ border: 'none' }}
          style={{ textDecoration: 'none' }}
        >
          <Text
            fontWeight={400}
            fontSize={isTablet ? '14px' : '16px'}
            lineHeight="24px"
            color="gray.700"
            _hover={ACTIVE_HOVER_STYLE}
            onMouseOver={() => setIsOthersHovered(true)}
            onMouseOut={() => setIsOthersHovered(false)}
          >
            Sign up as a Ka-Sari
          </Text>
        </Link>

        <Link
          href="https://sarisuki.freshteam.com/jobs"
          _focus={{ border: 'none' }}
          style={{ textDecoration: 'none' }}
        >
          <Text
            fontWeight={400}
            fontSize={isTablet ? '14px' : '16px'}
            lineHeight="24px"
            color="gray.700"
            _hover={ACTIVE_HOVER_STYLE}
            onMouseOver={() => setIsOthersHovered(true)}
            onMouseOut={() => setIsOthersHovered(false)}
          >
            Work at SariSuki
          </Text>
        </Link>

        <Link
          href="mailto: support@sarisuki.com"
          _focus={{ border: 'none' }}
          style={{ textDecoration: 'none' }}
        >
          <Text
            fontWeight={400}
            fontSize={isTablet ? '14px' : '16px'}
            lineHeight="24px"
            color="gray.700"
            _hover={ACTIVE_HOVER_STYLE}
            onMouseOver={() => setIsOthersHovered(true)}
            onMouseOut={() => setIsOthersHovered(false)}
          >
            Contact Us
          </Text>
        </Link>

        {!isTablet && <HomeLoginSignupButton color={MAIN_BG_COLOR} />}
      </HStack>
    </HStack>
  );
};

export default LandingPageNavigationBar;
