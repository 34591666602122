import axios from 'axios';
import { browserName, browserVersion } from 'react-device-detect';

const ConversionsViewEvent = async (params: { ip_address: string }) => {
    const conversionsdata =
        {
            data: [
                {
                    event_name: 'View',
                    event_time: `${Math.floor(Date.now() / 1000)}`,
                    event_source_url: `${window.location}`,
                    action_source: 'website',
                    user_data: {
                        client_ip_address: `${params.ip_address}`,
                        client_user_agent: `${browserName}/${browserVersion}`,
                     },
                    opt_out: false,
                },
            ],
        };

    await axios.get(`/api/conversions?payload=${JSON.stringify(conversionsdata)}`);
};
export default ConversionsViewEvent;
