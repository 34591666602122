import { Box } from '@chakra-ui/react';
import { BoxProps, Text } from '@chakra-ui/layout';

interface IProps extends BoxProps {}

const PresyongAbotKaya: React.FC<IProps> = ({ ...restProps }: IProps) => (
  <Box
    className="palengke-prices"
    borderRadius="8px"
    boxShadow="lg"
    h="96px"
    {...restProps}
  >
    <Text
      fontWeight={700}
      fontSize="24px"
      lineHeight="32px"
      color="white"
      py="16px"
      px="20px"
    >
      PRESYONG <br /> ABOT KAYA
    </Text>
  </Box>
);

export default PresyongAbotKaya;
