import { Image } from '@chakra-ui/image';

interface IProps {
  width: string;
  height: string;
}

const Logo: React.FC<IProps> = ({ width, height }: IProps) => (
  <Image src="/images/logo.svg" width={width} height={height} alt="sarisuki-logo" />
);

export default Logo;
