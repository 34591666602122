import { Button, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Spacer, VStack } from '@chakra-ui/react';
import { useMobileMediaQuery, useTabletMediaQuery } from '@hooks/responsive_hook';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { FiChevronDown, FiUser } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { isUserLoggedIn, selectLogin, logout, getSukiInfo } from '@sarisuki/login/loginSlice';
import { setUserFromCheckout } from '@sarisuki/signup/signupSlice';
import { setUserFromBanner } from '@sarisuki/referral/referralSlice';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

interface IProps {
  color: string;
}

const HomeLoginSignupButton: React.FC<IProps> = ({ color }: IProps) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const { sukiInfo } = useAppSelector(selectLogin);
  const [showAccountOptions, setShowAccountOptions] = useState(false);
  const router = useRouter();
  const { storeUrl } = router.query;
  const dispatch = useAppDispatch();

  const onLoginSignupClick = () => {
    const isInCheckout = window.location.pathname.includes(`/${storeUrl}/checkout`);

    if (isInCheckout) {
      dispatch(setUserFromCheckout(true));
    } else {
      dispatch(setUserFromCheckout(false));
    }

    router.replace('/login');
  };

  const onMyAccountPressed = () => {
    dispatch(setUserFromBanner(false));
    router.push('/account/');
  };

  const onLogoutPressed = () => {
    logout();
    router.push(`/${storeUrl}/`);
  };

  useEffect(() => {
    if (isUserLoggedIn() && !sukiInfo) {
      dispatch(getSukiInfo());
    }
  }, []);

  return (
    <>
      {isUserLoggedIn() && (isMobile || isTablet) &&
        <VStack spacing={0} w="100%">
          <HStack w="100%">
            <Button
              leftIcon={<Icon as={FiUser} color="gray.600" boxSize={6} />}
              p={0}
              justifyContent="flex-start"
              _focus={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              _hover={{ bg: 'none' }}
              bg="white"
              fontSize="20px"
              lineHeight="24px"
              fontWeight={700}
              color="gray.800"
              w="100% "
              onClick={() => (showAccountOptions ?
                setShowAccountOptions(false) : setShowAccountOptions(true))
              }
            >
              Hi, {sukiInfo?.first_name}
            </Button>
            <Spacer />
            <Icon as={showAccountOptions ? IoIosArrowUp : IoIosArrowDown} color="orange.500" boxSize={6} />
          </HStack>

          {showAccountOptions &&
            <>
              <Button
                p={0}
                m={0}
                justifyContent="flex-start"
                _focus={{ bg: 'none' }}
                _active={{ bg: 'none' }}
                _hover={{ bg: 'none' }}
                bg="white"
                fontSize="14px"
                lineHeight="20px"
                fontWeight={400}
                color="gray.600"
                w="100%"
                onClick={onMyAccountPressed}
              >
                My Account
              </Button>

              <Button
                p={0}
                m={0}
                justifyContent="flex-start"
                _focus={{ bg: 'none' }}
                _active={{ bg: 'none' }}
                _hover={{ bg: 'none' }}
                bg="white"
                fontSize="14px"
                lineHeight="20px"
                fontWeight={400}
                color="gray.600"
                w="100%"
                onClick={onLogoutPressed}
              >
                Log out
              </Button>
            </>
          }
        </VStack>
      }

      {isUserLoggedIn() && (!isMobile && !isTablet) &&
        <Menu>
          <MenuButton
            mt={1}
            bg={color}
            color="gray.700"
            as={Button}
            _focus={{ bg: color }}
            _active={{ bg: color }}
            _hover={{ bg: color }}
            pr={0}
            fontWeight={400}
            fontSize="16px"
            lineHeight="24px"
            leftIcon={<Icon as={BiUser} boxSize={6} color="gray.400" />}
            rightIcon={<Icon as={FiChevronDown} boxSize={6} color="orange" />}
          >
            Hi, {sukiInfo?.first_name}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={onMyAccountPressed}>My Account</MenuItem>
            <MenuItem onClick={onLogoutPressed}>Logout</MenuItem>
          </MenuList>
        </Menu>
      }

      {!isUserLoggedIn() && (isMobile || isTablet) &&
        <Button
          p={0}
          m={0}
          bg="white"
          iconSpacing={3}
          leftIcon={<Icon as={BiUser} boxSize={6} color="gray.800" />}
          _focus={{ bg: 'white' }}
          _active={{ bg: 'white' }}
          _hover={{ bg: 'white' }}
          onClick={onLoginSignupClick}
          width="100%"
          justifyContent="flex-start"
          fontSize="20px"
          lineHeight="24px"
          fontWeight={700}
          color="gray.800"
        >
          Log in/Sign up
        </Button>
      }

      {!isUserLoggedIn() && (!isMobile && !isTablet) &&
        <Button
          placeSelf="center"
          ml="20px"
          p={0}
          h="42px"
          bg={color}
          iconSpacing={3}
          leftIcon={<Icon as={BiUser} boxSize={6} color="gray.400" />}
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          color="gray.700"
          _focus={{ border: 'none' }}
          _active={{ border: 'none' }}
          _hover={{ border: 'none' }}
          onClick={onLoginSignupClick}
        >
          Log in/Sign up
        </Button>
      }
    </>
  );
};
export default HomeLoginSignupButton;
